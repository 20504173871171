/* eslint-disable @typescript-eslint/no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Stack, Button, Typography, Box, CircularProgress, Checkbox } from '@mui/material';
import { buttonStyle } from './buttonStyle';
import { getPaymentLink, updateFieldsInfo, updateUserInfo } from '../Redux/actions';
import { firebaseAnalytics } from '../../../utils/helper';
import InsuranceInputField from './InputComponents/insuranceInputFiled';
import InsuranceDateField from './InputComponents/insuranceDateField';
import SimpleBackdrop from '../../../components/sharedComponents/backdropLoader/loader';
import InsuranceSelectField from './InputComponents/insuranceSelectField';
import { sendEventToClevertap } from '../../../actions/clevertapActions';

const BasicInfoPayment = () => {
  const { basicInfoPaymentDetails, paymentFormError, productDetailsInfo } = useSelector(
    (state) => state.InsuranceReducer,
  );
  const [dobData, setDobData] = useState({});
  const [checked, setChecked] = useState(true);
  const [displayedPremium, setDisplayedPremium] = useState(productDetailsInfo?.product_details?.data?.premium || 0);
  const dispatch = useDispatch();

  const { fieldsData, userDefaultDetails, selectBestOfferData, loader } = basicInfoPaymentDetails;
  const { premium } = productDetailsInfo?.product_details?.data || {};

  const form = useForm({
    defaultValues: userDefaultDetails,
  });

  const { register, handleSubmit, formState, getValues, setValue } = form;

  const { errors } = formState;

  const onSubmit = () => {
    firebaseAnalytics('insurance_action_pay_now_click');
    dispatch(
      sendEventToClevertap({
        auth_token: window.localStorage.getItem('authToken'),
        eventData: {
          event_name: 'Pay_Now_Page',
          event_property: { Insurance_type: productDetailsInfo?.product_details?.data?.type },
        },
      }),
    );
    let offerDiscount = null;
    if (selectBestOfferData?.[0]?.offer && selectBestOfferData?.[0]?.amount) {
      offerDiscount = btoa(
        JSON.stringify({
          items: [
            {
              quantity: 1,
              amount: premium ?? null,
              offerId: selectBestOfferData?.[0]?.offer?.id ?? null,
            },
          ],
          discount:
            selectBestOfferData?.[0]?.offer?.offer_type?.toLowerCase() === 'discount'
              ? selectBestOfferData?.[0]?.amount
              : 0,
          cashback:
            selectBestOfferData?.[0]?.offer?.offer_type?.toLowerCase() === 'cashback'
              ? selectBestOfferData?.[0]?.amount
              : 0,
        }),
      );
    }
    const isOfferDiscountAvailable = offerDiscount && checked;
    const formValues = getValues();
    const data = { ...formValues, ...dobData, ...(isOfferDiscountAvailable && { offerDiscount }) };
    const payload = {
      userData: {
        data,
        authToken: window.localStorage.getItem('authTokenFromOtp') || window.localStorage.getItem('authToken'),
      },
      loader: true,
    };

    dispatch(getPaymentLink(payload));
  };

  const handleUserUpdate = (callback) => {
    let formValues = getValues();
    formValues = { ...formValues, ...callback, ...dobData };
    const formFieldsData = Object.assign(
      {},
      ...fieldsData
        .filter((el) => el?.additional_info?.fetch_premium)
        .map((item) => ({ [item.key]: formValues?.[`${item?.key}`] })),
    );
    const payload = { formFieldsData, callback };
    dispatch(updateUserInfo(payload));
  };

  const handleFieldUpdate = (callback) => {
    const { updatedOptions, currentValue, updatedKey } = callback;
    let formValues = getValues();
    formValues = { ...formValues, ...currentValue, ...dobData };
    const formFieldsData = Object.assign(
      {},
      ...fieldsData
        .filter((el) => el?.additional_info?.fetch_premium)
        .map((item) => ({ [item.key]: formValues?.[`${item?.key}`] })),
    );
    const payload = { formFieldsData, updatedOptions, updatedKey, currentValue };
    dispatch(updateFieldsInfo(payload));
  };

  const handleDobData = (callback) => setDobData((prev) => ({ ...prev, ...callback }));

  const handleCheckboxChange = (event) => setChecked(event.target.checked);

  useEffect(() => {
    if (checked && selectBestOfferData?.[0]?.amount) {
      setDisplayedPremium(
        premium -
          (selectBestOfferData?.[0]?.offer?.offer_type?.toLowerCase() === 'discount'
            ? selectBestOfferData[0].amount
            : 0),
      );
    } else {
      setDisplayedPremium(premium);
    }
  }, [checked, premium, selectBestOfferData]);

  return (
    <>
      <SimpleBackdrop open={loader} />
      <Box className="basic-info-payment">
        <Typography variant="h6" fontWeight={700} textAlign={'center'} mb={3}>
          Basic Info
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={2}>
            {fieldsData?.map((field, id) => {
              const { type } = field;
              if (type === 'text' || type === 'number' || type === 'email') {
                return (
                  <InsuranceInputField
                    field={field}
                    key={id + type}
                    register={register}
                    errors={errors}
                    handleUserUpdate={handleUserUpdate}
                  />
                );
              }
              if (type === 'dropdown') {
                return (
                  <InsuranceSelectField
                    key={id + type}
                    register={register}
                    field={field}
                    errors={errors}
                    handleUserUpdate={handleUserUpdate}
                    handleFieldUpdate={handleFieldUpdate}
                    setValue={setValue}
                  />
                );
              }
              if (type === 'date') {
                return (
                  <InsuranceDateField
                    key={id + field}
                    field={field}
                    register={register}
                    errors={errors}
                    handleUserUpdate={handleUserUpdate}
                    handleDobData={handleDobData}
                  />
                );
              }
              return null;
            })}
            {selectBestOfferData?.[0]?.offer && selectBestOfferData?.[0]?.amount ? (
              <Box mb={2} display="flex" alignItems="center">
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="h6" fontWeight={500} fontSize="12px">
                  {' '}
                  {selectBestOfferData?.[0]?.displayText}{' '}
                </Typography>
              </Box>
            ) : (
              ''
            )}
            <Box mb={2}>
              <Typography color="error" variant="caption">
                {paymentFormError}
              </Typography>
            </Box>
            <Box textAlign={'center'}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                sx={buttonStyle}
                endIcon={loader ? <CircularProgress size="20px" color="inherit" /> : ''}
                disabled={loader}
              >
                {`₹${displayedPremium} - Pay Now`}
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default BasicInfoPayment;
